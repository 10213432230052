import { FiMoon, FiSun } from "../assets/icons/vander";
import "react-dropdown/style.css";

import CustomSelect from "./custom-select";

export default function Switcher() {
    let htmlTag = document.getElementsByTagName("html")[0];

    const changeTheme = (e) => {
        if (htmlTag.className.includes("dark")) {
            htmlTag.className = "light";
        } else {
            htmlTag.className = "dark";
        }
    };

    return (
        <div className="flex items-center justify-center gap-2">
            <div className="">
                <span className="relative inline-block">
                    <input
                        type="checkbox"
                        className="checkbox opacity-0 absolute"
                        id="chk"
                        onChange={(e) => changeTheme(e)}
                    />
                    <label
                        className="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
                        htmlFor="chk"
                    >
                        <FiMoon className="w-[18px] h-[18px] text-yellow-500"></FiMoon>
                        <FiSun className="w-[18px] h-[18px] text-yellow-500"></FiSun>
                        <span className="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] w-7 h-7 transition-transform duration-300"></span>
                    </label>
                </span>
            </div>

            <div className="md:mb-2">
                <CustomSelect />
            </div>
        </div>
    );
}
