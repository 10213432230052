import React from "react";
import { Link } from "react-router-dom";

import { handleGetLanguage } from "../utils/languageStorage";

export default function Footer() {
    const language = handleGetLanguage();
    return (
        <footer className="bottom-0 pt-5 footer bg-dark-footer  text-gray-200 dark:text-gray-200">
            <div className="container mx-auto px-4 md:px-0 text-center flex flex-col items-center">
                <Link to="#" className="text-[22px] focus:outline-none">
                    <h1 className="text-2xl text-white">OnlyBrands</h1>
                </Link>
                <p className="mt-4 text-gray-300 text-sm md:text-base max-w-lg">
                    {language === "ka"
                        ? "განაახლეთ თქვენი სტილი ჩვენი კურირებული ნაკრებით. აირჩიე ნდობა, მიიღე შენი უნიკალური სახე."
                        : "Upgrade your style with our curated sets. Choose confidence, embrace your unique look."}
                </p>
            </div>

            <div className="py-6 px-4 md:px-0 border-t border-slate-800">
                <div className="container mx-auto text-center md:flex md:justify-between">
                    <Link
                        to={"https://quantum-soft.org"}
                        className="mb-2 md:mb-0 text-gray-400 text-xs md:text-sm"
                    >
                        Developed by QSOFT - Quantum Soft
                    </Link>
                    <p className="mb-2 md:mb-0 font-bold text-xs md:text-sm">
                        Copyright © 2023 - 2024 onlybrands.ge. ყველა უფლება დაცულია.
                    </p>
                    <p className="text-gray-400 text-xs md:text-sm">
                        Website Protected By <Link to="https://quantum-security.org">QSEC</Link>
                    </p>
                </div>
            </div>
        </footer>
    );
}
