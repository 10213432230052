import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import axiosInstance from "../../../utils/axiosInstance";
import { toast } from "sonner";
import { handleSetAuthCookie } from "../../../utils/token";
import useUserData from "../../../store/userStore";
import useAuthModal from "../../../store/authWarningModal";
import { handleGetLanguage } from "../../../utils/languageStorage";

export default function Login({ setTogglAuth }) {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const router = useNavigate();
    const { setUsetData } = useUserData((state) => state);
    const { hide } = useAuthModal((state) => state);

    const language = handleGetLanguage();

    const loginHandler = async (e) => {
        e.preventDefault();

        try {
            if (!email || !password) {
                toast.error("Write email and password");
                return;
            }

            const loginResponse = await axiosInstance.post("/login", { email, password });
            handleSetAuthCookie(loginResponse.data.access_token);

            axiosInstance
                .get("/user")
                .then((res) => {
                    setUsetData(res.data);
                    toast.success(
                        language === "ka" ? "იუზერი შემოვიდა წარმატებით ✔" : "User logged in ✔"
                    );
                })
                .catch((err) => toast.error("Can't fetch user data"));

            router("/");
            setEmail("");
            setPassword("");
            hide();
        } catch (err) {
            console.log(err);
            if (err.response.status === 422) {
                toast.error(err.response.data.errors.email[0][language]);
            }
            toast.error(err.response.data[language]);
        }
    };

    return (
        <>
            <form
                action=""
                onSubmit={loginHandler}
                className="flex flex-col items-center gap-4 w-full max-w-sm mx-auto p-4"
            >
                <h4 className="mb-8  font-bold text-lg text-black dark:text-white text-center">
                    {language === "ka"
                        ? "გაიარე ავტორიზაცია ან შექმენი ახალი"
                        : "Sign in or create Personal page"}
                </h4>
                <input
                    placeholder={language === "ka" ? "შეიყვანეთ მეილი" : "Enter your email"}
                    type="text"
                    className="w-full py-3 px-7 rounded-full dark:bg-slate-900 text-black dark:text-white focus:outline-none focus:ring-0 shadow-lg"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="relative w-full">
                    <input
                        className="w-full py-3 px-7 rounded-full dark:bg-slate-900 text-black dark:text-white pr-10 focus:outline-none focus:ring-0 shadow-lg"
                        placeholder={language === "ka" ? "შეიყვანე პაროლი" : "Enter your password"}
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                        className="absolute inset-y-0 right-0 pr-6 flex items-center cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <AiOutlineEyeInvisible className="text-black dark:text-white" />
                        ) : (
                            <AiOutlineEye className="text-black dark:text-white" />
                        )}
                    </div>
                </div>
                <div className="w-full">
                    <button className="py-1.5 px-3 rounded-full bg-orange-500 text-white hover:opacity-75 mt-4 w-full">
                        <p>{language === "ka" ? "ავტორიზაცია" : "Sign in"}</p>
                    </button>
                    <p className="text-center text-black dark:text-white mt-2">
                        {language === "ka" ? "არ ხარ დარეგისტრირებული?" : "Don't have an account?"}
                    </p>
                    <button
                        onClick={() => setTogglAuth("Signup")}
                        className="py-1.5 px-3 rounded-full bg-white text-black border border-black hover:bg-gray-100 mt-2 w-full"
                    >
                        <p> {language === "ka" ? "რეგისტრაცია" : "Sign up"}</p>
                    </button>
                </div>
            </form>
        </>
    );
}
