export const LANGUAGE_KEY = "language";

export const handleSetLanguage = (language) => {
    if (language === "en" || language === "ka") {
        localStorage.setItem(LANGUAGE_KEY, language);
    } else {
        console.error('Invalid language code. Please use "en" or "ka".');
    }
};

export const handleGetLanguage = () => {
    return localStorage.getItem(LANGUAGE_KEY) || "en";
};

export const handleDeleteLanguage = () => {
    localStorage.removeItem(LANGUAGE_KEY);
};
