import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiSearch, IoIosArrowDown, IoIosArrowUp } from "../assets/icons/vander";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../utils/axiosInstance";
import { handleGetLanguage } from "../utils/languageStorage";
import FilterCategories from "./filter-Categories";

// RangeSelector Component
const RangeSelector = ({ onSelect, language }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [from, setFrom] = useState("0");
    const [to, setTo] = useState("");

    const removeQuery = (key) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(key);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
    };
    const handleRangeChange = () => {
        const url = onSelect("price", `${from},${to}`);
        if (!from && !to) {
            removeQuery("price");
        } else {
            navigate(url);
        }
    };

    return (
        <div className="mt-4 text-xl">
            <h5 className="font-medium ">{language === "en" ? "Price Range" : "ფასი"}:</h5>
            <div className="flex flex-col sm:flex-row justify-between items-center mt-2">
                <input
                    type="text"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    className="w-full sm:w-[48%] p-2 border rounded mb-2 sm:mb-0 dark:bg-transparent dark:border-gray-800"
                    placeholder={language === "en" ? "From" : "დან"}
                />
                <input
                    type="text"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    className="w-full sm:w-[48%] p-2 border rounded dark:bg-transparent dark:border-gray-800"
                    placeholder={language === "en" ? "To" : "მდე"}
                />
            </div>
            <button
                onClick={handleRangeChange}
                className="mt-4 w-full sm:w-auto px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
            >
                {language === "en" ? "Choose Price" : "ფასის არჩევა"}
            </button>
        </div>
    );
};

export default function Filter({
    genderQuery,
    brandQuery,
    sizeQuery,
    colorQuery,
    typeQuery,
    removeQuery,
    updateQuery,
    searchQuery,
    categorieQuery,
}) {
    const fetchProductFilters = async () => {
        const response = await axiosInstance.get(`/products/filters`);
        if (response.status !== 200) {
            throw new Error("Something went wrong");
        }
        return response.data;
    };

    const fetchCategories = async () => {
        const response = await axiosInstance.get(`/categories`);
        if (response.status !== 200) {
            throw new Error("Something went wrong");
        }
        return response.data;
    };
    const [searchValue, setSearchValue] = useState("");
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [isBrandsVisible, setIsBrandsVisible] = useState(false); // State for toggling brands visibility
    const language = handleGetLanguage();
    const navigate = useNavigate();

    const { data, isLoading, error } = useQuery({
        queryKey: ["ProductFilters"],
        queryFn: () => fetchProductFilters(),
    });

    const { data: categoriesData } = useQuery({
        queryKey: ["categories"],
        queryFn: () => fetchCategories(),
    });

    const findQueryWithId = (data, query) => {
        const result = data?.find((item) => item.id === +query);
        return result;
    };

    const size = findQueryWithId(data?.sizes, sizeQuery);
    const brand = findQueryWithId(data?.brands, brandQuery);
    const categorie = findQueryWithId(categoriesData, categorieQuery);
    const color = findQueryWithId(data?.colors, colorQuery);

    const translations = {
        en: {
            filter: "Filter",
            search: "Search",
            colors: "Colors",
            brands: "Brands",
            sizes: "Sizes",
            women: "Women",
            men: "Men",
            kid: "Kid",
        },
        ka: {
            filter: "ფილტრი",
            search: "ძიება",
            colors: "ფერები",
            brands: "ბრენდები",
            sizes: "ზომები",
            women: "ქალები",
            men: "მამაკაცები",
            kid: "ბავშვი",
        },
    };

    const t = translations[handleGetLanguage()] || translations.en;

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            navigate(updateQuery("search", searchValue));
        }
    };

    // Toggle the brands visibility
    const toggleBrandsVisibility = () => {
        setIsBrandsVisible(!isBrandsVisible);
    };

    // Function to handle gender toggle
    const handleGenderClick = (genderId) => {
        if (+genderQuery === genderId) {
            // If the clicked gender is already selected, remove the filter
            navigate(removeQuery("gender"));
        } else {
            // Otherwise, set the new gender filter
            navigate(updateQuery("gender", genderId));
        }
    };

    return (
        <div className="mt-5 md:mt-0 lg:col-span-3 md:col-span-4 ">
            {/* Toggle button visible on small screens */}
            <div className="md:hidden ">
                <button
                    className="w-full text-left p-2 rounded-lg border dark:bg-gray-700"
                    onClick={() => setIsFilterVisible(!isFilterVisible)}
                >
                    {t.filter} {isFilterVisible ? "▲" : "▼"}
                </button>
            </div>

            {/* Filter content */}
            <div
                className={`rounded shadow dark:shadow-gray-800 p-4 sticky top-20 md:block ${
                    isFilterVisible ? "block" : "hidden"
                }`}
            >
                <h5 className="text-xl font-medium mb-3">{t.filter}</h5>

                <div className="grid grid-cols-2 gap-4">
                    {brandQuery && (
                        <div className="text-[18px]  flex items-center justify-between border px-3 py-1 rounded-lg">
                            <p className="mr-[10px] max-w-[90%] truncate">
                                {brand?.name[language]}
                            </p>
                            <Link to={removeQuery("brands")} className="w-[10%] text-red-500">
                                <i className="mdi mdi-window-close text-red-600"></i>
                            </Link>
                        </div>
                    )}
                    {sizeQuery && (
                        <div className="text-[18px]  flex items-center justify-between border px-3 py-1 rounded-lg">
                            <p className="mr-[10px] max-w-[90%] truncate">{size?.name}</p>
                            <Link to={removeQuery("sizes")} className="w-[10%] text-red-500">
                                <i className="mdi mdi-window-close text-red-600"></i>
                            </Link>
                        </div>
                    )}
                    {categorieQuery && (
                        <div className="text-[18px]  flex items-center justify-between border px-3 py-1 rounded-lg">
                            <p className="mr-[10px] max-w-[90%] truncate">
                                {categorie?.name[language]}
                            </p>
                            <Link to={removeQuery("categorie")} className="w-[10%] text-red-500">
                                <i className="mdi mdi-window-close text-red-600"></i>
                            </Link>
                        </div>
                    )}
                    {colorQuery && (
                        <div className="text-[18px]  flex items-center justify-between border px-3 py-1 rounded-lg">
                            <p className="mr-[10px] max-w-[90%] truncate">
                                {color?.name[language]}
                            </p>
                            <Link to={removeQuery("colors")} className="w-[10%] text-red-500">
                                <i className="mdi mdi-window-close text-red-600"></i>
                            </Link>
                        </div>
                    )}
                    {typeQuery && (
                        <div className="text-[18px]  flex items-center justify-between border px-3 py-1 rounded-lg">
                            <p className="mr-[10px] max-w-[90%] truncate">
                                {typeQuery === "sales"
                                    ? "sales"
                                    : typeQuery === "is_trending"
                                    ? "trending"
                                    : "best seller"}
                            </p>
                            <Link to={removeQuery("type")} className="w-[10%] text-red-500">
                                <i className="mdi mdi-window-close text-red-600"></i>
                            </Link>
                        </div>
                    )}
                    {genderQuery && (
                        <div className="text-[18px]  flex items-center justify-between border px-3 py-1 rounded-lg">
                            <p className="mr-[10px] max-w-[90%] truncate">
                                {+genderQuery === 1 ? t.women : +genderQuery === 2 ? t.men : t.kid}
                            </p>
                            <Link to={removeQuery("gender")} className="w-[10%] text-red-500">
                                <i className="mdi mdi-window-close text-red-600"></i>
                            </Link>
                        </div>
                    )}
                </div>

                {/* Enhanced Gender Filter Section */}
                <div className="mt-4 flex gap-2 lg:gap-3 xl:gap-5 items-center text-lg  flex-wrap">
                    {/* Gender Buttons */}
                    <button
                        onClick={() => handleGenderClick(1)}
                        className={`px-2 py-1 lg:px-3 lg:py-1 xl:px-4 xl:py-2 rounded-lg border transition-colors duration-300 flex items-center justify-center
                            ${
                                +genderQuery === 1
                                    ? "bg-orange-500 text-white border-orange-500"
                                    : "bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 border-gray-300 hover:bg-orange-100 dark:hover:bg-gray-700"
                            }`}
                    >
                        {language === "ka" ? "ქალები" : "Women"}
                    </button>
                    <button
                        onClick={() => handleGenderClick(2)}
                        className={`px-2 py-1 lg:px-3 lg:py-1 xl:px-4 xl:py-2 rounded-lg border transition-colors duration-300 flex items-center justify-center
                            ${
                                +genderQuery === 2
                                    ? "bg-orange-500 text-white border-orange-500"
                                    : "bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 border-gray-300 hover:bg-orange-100 dark:hover:bg-gray-700"
                            }`}
                    >
                        {language === "ka" ? "კაცები" : "Men"}
                    </button>
                    <button
                        onClick={() => handleGenderClick(3)}
                        className={`px-2 py-1 lg:px-3 lg:py-1 xl:px-4 xl:py-2 rounded-lg border transition-colors duration-300 flex items-center justify-center
                            ${
                                +genderQuery === 3
                                    ? "bg-orange-500 text-white border-orange-500"
                                    : "bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 border-gray-300 hover:bg-orange-100 dark:hover:bg-gray-700"
                            }`}
                    >
                        {language === "ka" ? "ბავშვები" : "Kids"}
                    </button>
                </div>

                <div className="my-4 text-xl">
                    <button
                        onClick={toggleBrandsVisibility}
                        className="w-full  flex justify-between"
                    >
                        <h5 className="font-medium flex items-center justify-between">
                            {t.brands}
                        </h5>
                        <p>{isBrandsVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
                    </button>
                    {isBrandsVisible && (
                        <ul className="list-none mt-2 flex flex-col gap-3">
                            {data?.brands?.map((item, index) => (
                                <li key={index} className="ms-0 ">
                                    <Link
                                        to={updateQuery("brands", item.id)}
                                        className={`dark:text-gray-100 hover:text-orange-500 ${
                                            brand?.name === item?.name[language] &&
                                            "text-orange-500 dark:text-orange-500"
                                        }`}
                                    >
                                        <i className="mdi mdi-shopping-outline text-orange-500 me-2"></i>
                                        {item.name[language]}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <FilterCategories />
                <div className="mt-4">
                    <h5 className="font-medium text-xl">
                        {language === "en" ? "Colors" : "ფერები"}:
                    </h5>
                    <ul className="list-none mt-2">
                        {data?.colors?.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={`inline ${
                                        +colorQuery === +item.id &&
                                        "border-[5px] border-black rounded-full"
                                    }`}
                                >
                                    <Link
                                        to={updateQuery("colors", item.id)}
                                        className={`size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 inline-flex align-middle `}
                                        style={{ backgroundColor: item?.color_hex }}
                                        title={item}
                                    ></Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                {/* RangeSelector Component */}
                <RangeSelector language={language} onSelect={updateQuery} />

                <form className="mt-4" onSubmit={(e) => e.preventDefault()}>
                    <div>
                        <label htmlFor="searchname" className="font-medium">
                            {t.search}:
                        </label>
                        <div className="relative mt-2 ">
                            <Link to={searchValue && updateQuery("search", searchValue)}>
                                <FiSearch className="absolute size-4 top-[9px] end-4 text-slate-900 dark:text-white"></FiSearch>
                            </Link>
                            <input
                                type="text"
                                className="w-full h-9 pe-10 rounded px-3 border border-gray-100 dark:border-gray-800 focus:ring-0 outline-none bg-white dark:bg-slate-900"
                                name="s"
                                id="searchItem"
                                placeholder={t.search}
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyPress={handleKeyPress}
                                defaultValue={searchQuery && searchQuery}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
