import Cookies from "js-cookie";

const AUTH_COOKIE_NAME = "auth";

export const handleSetAuthCookie = (token) => {
    Cookies.set(AUTH_COOKIE_NAME, token, { expires: 1 });
};

export const handleGetAuthCookie = () => {
    const cookieValue = Cookies.get(AUTH_COOKIE_NAME);

    return cookieValue;
};
export const handleDeleteAuthCookie = () => {
    Cookies.remove(AUTH_COOKIE_NAME);
};
