import React from "react";
import { handleGetLanguage } from "../utils/languageStorage";

export default function Tagline() {
    const language = handleGetLanguage();
    return (
        <div className="tagline bg-slate-900">
            <div className="container relative">
                <div className="grid grid-cols-1">
                    <div className="text-center">
                        <h6 className="text-white font-medium">
                            {language === "ka"
                                ? "მიმართეთ მეგობარს და მიიღეთ $50 კრედიტი თითოეული 🎉"
                                : "Refer a friend & get $50 in credits each 🎉"}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    );
}
