import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../utils/axiosInstance";
import Loading from "../../../components/loading";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import ScrollToTop from "../../../components/scroll-to-top";
import { handleGetLanguage } from "../../../utils/languageStorage";

const fetchOrders = async () => {
    const response = await axiosInstance.get(`/orders`);
    if (response.status !== 200) {
        throw new Error("Something went wrong");
    }
    return response.data;
};

export default function Favorites() {
    const language = handleGetLanguage();
    const {
        data,
        isLoading: isOrdersLoading,
        error,
    } = useQuery({
        queryKey: ["orders"],
        queryFn: fetchOrders,
    });

    const translations = {
        en: {
            favorites: "Orders",
            Onlybrands: "Onlybrands",
            favoritesPage: "ORDERS",
            failedToLoad: "Failed to load ordered products.",
            noData: "No orders found.",
            totalCost: "Total Cost",
            date: "Date",
            productName: "Product Name",
        },
        ka: {
            favorites: "შეკვეთილები",
            Onlybrands: "მხოლოდ ბრენდები",
            favoritesPage: "შეკვეთილები",
            failedToLoad: "შეკვეთილი პროდუქტების ჩატვირთვა ვერ მოხერხდა.",
            noData: "შეკვეთილი პროდუქტები არ მოიძებნა.",
            totalCost: "სულ ღირებულება",
            date: "თარიღი",
            productName: "პროდუქტის სახელი",
        },
    };

    const t = translations[language] || translations.en;

    const formatDate = (dateString) => {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        };
        return new Intl.DateTimeFormat(language, options).format(new Date(dateString));
    };

    if (isOrdersLoading) {
        return <Loading />;
    }

    console.log(data);

    return (
        <div className="flex flex-col min-h-screen">
            {/* Navbar */}
            <Navbar navClass="defaultscroll is-sticky" />

            {/* Main Content */}
            <main className="flex-grow">
                {/* Header Section */}
                <section className="w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
                    <div className="container mx-auto px-4">
                        <div className="text-center mb-8">
                            <h1 className="text-4xl md:text-5xl font-bold text-gray-800 dark:text-white">
                                {t.favorites}
                            </h1>
                        </div>

                        {/* Breadcrumbs */}
                        <div className="text-center">
                            <ul className="flex justify-center items-center space-x-2 md:space-x-4">
                                <li>
                                    <Link
                                        to="/"
                                        className="uppercase text-sm md:text-base font-semibold text-gray-500 dark:text-gray-400 hover:text-orange-500 transition duration-300"
                                    >
                                        {t.Onlybrands}
                                    </Link>
                                </li>
                                <li>
                                    <i className="mdi mdi-chevron-right text-gray-500 dark:text-gray-400"></i>
                                </li>
                                <li
                                    className="uppercase text-sm md:text-base font-semibold text-orange-500"
                                    aria-current="page"
                                >
                                    {t.favoritesPage}
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Content Section */}
                <section className="w-full py-16 lg:py-24 bg-gray-50 dark:bg-slate-800">
                    <div className="container mx-auto px-4">
                        {error ? (
                            <div className="text-center">
                                <p className="text-red-500 text-lg md:text-xl">{t.failedToLoad}</p>
                            </div>
                        ) : data.length === 0 ? (
                            <div className="text-center">
                                <p className="text-gray-700 dark:text-gray-300 text-lg md:text-xl">
                                    {t.noData}
                                </p>
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
                                {data?.map((item) => (
                                    <div
                                        key={item.id}
                                        className="order-card w-full bg-white dark:bg-slate-700 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 dark:shadow-gray-800 flex flex-col justify-between"
                                    >
                                        {/* Product Images and Names */}
                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            {item?.items?.map((product) => (
                                                <div
                                                    key={product.id}
                                                    className="flex flex-col items-center"
                                                >
                                                    <img
                                                        src={product?.product.images[0]}
                                                        alt={product?.product?.name[language]}
                                                        className="w-20 h-20 lg:w-24 lg:h-24 rounded-lg object-cover shadow-lg transform transition-transform duration-200 hover:scale-105"
                                                    />
                                                    <span className="text-center text-base lg:text-lg font-semibold text-gray-800 dark:text-white mt-2">
                                                        {product?.product?.name[language] ||
                                                            product?.product?.name[language]}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>

                                        {/* Total Cost */}
                                        <div className="text-center text-lg font-semibold text-gray-800 dark:text-white mb-2">
                                            {t.totalCost}:{" "}
                                            <span className="font-bold">{item.total_cost} GEL</span>{" "}
                                            ({item.total_amount})
                                        </div>

                                        {/* Order Date */}
                                        <div className="text-center text-sm md:text-base text-gray-600 dark:text-gray-300">
                                            {formatDate(item.created_at)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </section>
            </main>

            <Footer />

            <ScrollToTop />
        </div>
    );
}
