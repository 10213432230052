import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollToTop from "../../components/scroll-to-top";

import about1 from "../../assets/images/ab3.jpg";
import about2 from "../../assets/images/ab4.jpg";
import about3 from "../../assets/images/ab1.jpg";

import { handleGetLanguage } from "../../utils/languageStorage";
import useUserData from "../../store/userStore";
import useAuthModal from "../../store/authWarningModal";
import IbanModal from "../../components/ibanModal";

export default function Career() {
    const language = handleGetLanguage();
    const [isModalOpean, setIsModalOpean] = useState(false);

    const { user } = useUserData((state) => state);
    const { show } = useAuthModal((state) => state);

    const clickHandler = () => {
        if (!user.name) {
            show("SignIn");
        } else {
            setIsModalOpean(true);
        }
    };
    return (
        <>
            <Navbar navClass="defaultscroll is-sticky" navlight={true} />
            {isModalOpean && <IbanModal setIsOpen={setIsModalOpean} />}
            <section className="relative table w-full py-36 lg:py-44 bg-[url('../../assets/images/hero/pages.jpg')] bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-12">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">
                            {language === "ka" ? "რეფერალი" : "Referral"}
                        </h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
                            <Link to="/">Onlybrands</Link>
                        </li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                            <i className="mdi mdi-chevron-right"></i>
                        </li>
                        <li
                            className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
                            aria-current="page"
                        >
                            {language === "ka" ? "რეფერალი" : "Referral"}
                        </li>
                    </ul>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="grid grid-cols-12 gap-6 items-center">
                                <div className="col-span-6">
                                    <div className="grid grid-cols-1 gap-6">
                                        <img src={about1} className="shadow rounded-md" alt="" />
                                        <img src={about2} className="shadow rounded-md" alt="" />
                                    </div>
                                </div>

                                <div className="col-span-6">
                                    <div className="grid grid-cols-1 gap-6">
                                        <img src={about3} className="shadow rounded-md" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6">
                            <div className="lg:ms-5">
                                <h3 className="mb-6 font-semibold text-3xl leading-normal">
                                    {language === "ka"
                                        ? "ჩვენ ვართ უდისესი ბრენდების მაღაზია"
                                        : "We are Largest Brands Shop"}{" "}
                                    <br />
                                    {language === "ka" ? "საქართველოში" : "in Georgia"}
                                </h3>

                                <p className="text-slate-400 max-w-xl">
                                    {language === "ka"
                                        ? "Onlybrands.ge  გთავაზობთ გახდეთ მისი  პარტნოირი, აღნიშნულისათვის საჭიროა საიტზე არსებული ნებისმიერი ნივთი გააზიაროთ სოციალურ ქსელში. თუ თქვენი გაზიარებული ბმულის  მშვეობით     მომხმარებელი გადავა საიტზე    და   შეიძენს თქვენს მიერ გაზიარებულ ნივთს, ან საიტზე განთავსებული ნივთებიდან სხვა ნებისმიერ ნივთს,   თქვენ მიიღებთ გაყიდული პროდუქციის 1,5%.  თქვენს მიერ გაზიარებულ  ბმულს  თანხის გამომუშავების რესურსი ექნება გაზიარებიდან  72 საათში.  გამომუშავებული თანხა იასახებას თქვენს პირად კაბინეტში და დაგერიცხებათ საიტზე დაფიქსირებულ ანგარიშზე 10 სამუშაო დღეში.  თქვენ არ ხართ გაყიდული პროდუქციის ხარისხსა და მიტანის სერვისზე პასუხმგებელი, აღნიშული პასუხმგებლობა ეკისრება Onlybrands.ge-ს. მოახდინე   ორიგინალი პროდუქციის რეალიზაცია   და    მიიღე  შემოსავალი. "
                                        : "Onlybrands.ge offers you to become its partner, for this you need to share any item on the site on social networks. If a user goes to your site through your shared link and buys the item you shared, or any other item on the site, you will receive 1.5% of the product sold. The link you share will be monetized within 72 hours of sharing. Earned money will be deposited in your personal account and will be credited to your account registered on the website within 10 working days. You are not responsible for the quality of the products sold and the delivery service, the said responsibility rests with Onlybrands.ge. Sell ​​original products and get income."}
                                </p>

                                <div className="mt-6">
                                    <button
                                        onClick={clickHandler}
                                        className="text-white bg-orange-500 px-4 py-2 rounded-lg hover:bg-orange-400 "
                                    >
                                        {language === "ka"
                                            ? "გასხდი პარტნიორი"
                                            : "Become a partner"}
                                        <i className="mdi mdi-chevron-right align-middle"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollToTop />
        </>
    );
}
