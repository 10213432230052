import React from "react";

import ShopItemCard from "./shop-item-card";

export default function ArrivalItem({ data, language }) {
    return (
        <div className="container lg:mt-24 mt-16">
            <div className="grid grid-cols-1 mb-6 text-center">
                <h3 className="font-semibold text-3xl leading-normal">
                    {language === "ka" ? "ახალი პროდუქები" : "New Arrival Items"}
                </h3>
            </div>

            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 pt-6">
                {data?.map((item, index) => {
                    return <ShopItemCard item={item} key={index} />;
                })}
            </div>
        </div>
    );
}
