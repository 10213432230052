import React, { useEffect } from "react";
import brandData from "../data/brands";
import "../assets/css/brandSlider.css";

export default function BrandSlider() {
    useEffect(() => {
        const scrollers = document.querySelectorAll(".scroller");

        // If user has not opted for reduced motion, add animation
        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            addAnimation();
        }

        function addAnimation() {
            scrollers.forEach((scroller) => {
                // Add data-animated="true" to every .scroller
                scroller.setAttribute("data-animated", true);

                const scrollerInner = scroller.querySelector(".scroller__inner");
                const scrollerContent = Array.from(scrollerInner.children);

                // Duplicate each item
                scrollerContent.forEach((item) => {
                    const duplicatedItem = item.cloneNode(true);
                    duplicatedItem.setAttribute("aria-hidden", true);
                    scrollerInner.appendChild(duplicatedItem);
                });
            });
        }
    }, []);

    return (
        <div className=" mx-auto flex flex-col gap-6 mb-2  md:mb-10">
            <div className="scroller overflow-hidden" data-speed="slow" data-direction="left">
                <div className="scroller__inner flex gap-5 md:gap-10 items-center">
                    {brandData.map((brand) => (
                        <img
                            key={brand.id}
                            alt={brand.title}
                            src={brand.logo}
                            className="h-10 md:h-15 lg:h-20 rounded-lg p-2 object-contain"
                        />
                    ))}
                </div>
            </div>

            {/* Second slider - right scrolling */}
            {/* <div className="scroller overflow-hidden" data-speed="slow" data-direction="right">
                <div className="scroller__inner flex gap-5 md:gap-10 items-center">
                    {brandData.map((brand) => (
                        <img
                            key={brand.id}
                            alt={brand.title}
                            src={brand.logo}
                            className="h-10 md:h-15 lg:h-20 rounded-lg p-2 object-contain"
                        />
                    ))}
                </div>
            </div> */}
        </div>
    );
}
