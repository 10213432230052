import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar";
import Usertab from "../../../components/user-tab";
import Footer from "../../../components/footer";
import {
    FiUser,
    FiPhone,
    FiKey,
    FiAlertCircle,
    FiCopy,
    FiEye,
    FiEyeOff,
} from "../../../assets/icons/vander";
import ScrollToTop from "../../../components/scroll-to-top";
import useUserData from "../../../store/userStore";
import axiosInstance from "../../../utils/axiosInstance";
import { toast } from "sonner";
import { handleDeleteAuthCookie } from "../../../utils/token";
import { handleGetLanguage } from "../../../utils/languageStorage";

export default function UserSetting() {
    const [deleteModal, setDeleteModal] = useState(false);
    const [isReferralVisible, setReferralVisible] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [withdrawModal, setWithdrawModal] = useState(false);
    const { user } = useUserData((state) => state);
    const language = handleGetLanguage();

    const handleCopy = () => {
        navigator.clipboard.writeText(user?.referral_code);
        toast.success("Referral code copied to clipboard");
    };

    const toggleReferralVisibility = () => {
        setReferralVisible(!isReferralVisible);
    };

    const translations = {
        en: {
            personalDetail: "Personal Detail",
            userName: "User Name",
            phoneNumber: "Phone No.",
            saveChanges: "Save Changes",
            changePassword: "Change password",
            oldPassword: "Old password",
            newPassword: "New password",
            retypeNewPassword: "Re-type New password",
            savePassword: "Save password",
            deleteAccount: "Delete Account",
            deleteConfirmation:
                'Do you want to delete the account? Please press below "Delete" button',
            confirmDeletion: "Confirm Deletion",
            confirmDeletionMsg:
                "Are you sure you want to delete your account? This action cannot be undone.",
            cancel: "Cancel",
            delete: "Delete",
            withdrawMonay: "Withdraw money",
            withDrawHeader: "Do you want to withdraw money ?",
            withDrawText: "Enter the desired amount",
        },
        ka: {
            personalDetail: "პირადი დეტალები",
            userName: "მომხმარებლის სახელი",
            phoneNumber: "ტელეფონის ნომერი",
            saveChanges: "ცვლილებების შენახვა",
            changePassword: "პაროლის შეცვლა",
            oldPassword: "ძველი პაროლი",
            newPassword: "ახალი პაროლი",
            retypeNewPassword: "ახალი პაროლის გამეორება",
            savePassword: "პაროლის შენახვა",
            deleteAccount: "ანგარიშის წაშლა",
            deleteConfirmation: 'გსურთ ანგარიშის წაშლა? გთხოვთ, დააჭირეთ ქვემოთ ღილაკს "წაშლა"',
            confirmDeletion: "დასტურის წაშლა",
            confirmDeletionMsg:
                "დარწმუნებული ხართ, რომ გსურთ ანგარიშის წაშლა? ეს ქმედება არ შეიძლება გაუქმდეს.",
            cancel: "გაუქმება",
            delete: "წაშლა",
            withdrawMonay: "თანხის მოთხოვნა",
            withDrawHeader: "თანხის გამოტანა გსურთ?",
            withDrawText: "შეიყვანეთ სასურველი თანხა",
        },
    };

    const t = translations[language] || translations.en;

    const changeUserDetailsHandler = (e) => {
        e.preventDefault();
        const name = e.target.elements.name.value;
        const phone_number = e.target.elements.number.value;

        if (/\s/.test(name) || /\s/.test(phone_number)) {
            toast.error("Input fields cannot contain spaces.");
            return;
        }

        const body = {};

        if (name) {
            body.name = name;
        }

        if (phone_number) {
            body.phone_number = phone_number;
        }

        axiosInstance
            .patch("user/update", body)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(
                        language === "ka" ? "განახლდა წარმატებით" : "Updated successfully"
                    );
                }
            })
            .catch((err) =>
                toast.error(language === "ka" ? "წარუატებელია" : "Something went wrong")
            );

        e.target.elements.name.value = "";
        e.target.elements.number.value = "";
    };

    const changePasswordHandler = (e) => {
        e.preventDefault();
        const oldPassword = e.target.elements.old_password.value;
        const newPassword = e.target.elements.new_password.value;
        const newPasswordConfirmation = e.target.elements.new_password_confirmation.value;

        if (
            /\s/.test(oldPassword) ||
            /\s/.test(newPassword) ||
            /\s/.test(newPasswordConfirmation)
        ) {
            toast.error("Input fields cannot contain spaces.");
            return;
        }

        if (newPassword !== newPasswordConfirmation) {
            toast.error("New password and confirmation do not match.");
            return;
        }

        axiosInstance
            .post("user/change-password", {
                old_password: oldPassword,
                new_password: newPassword,
                new_password_confirmation: newPasswordConfirmation,
            })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Updated successfully");
                }
            })
            .catch((err) => toast.error(err.message));

        e.target.elements.old_password.value = "";
        e.target.elements.new_password.value = "";
        e.target.elements.new_password_confirmation.value = "";
    };

    const deleteAccountHandler = () => {
        axiosInstance.delete("/user/delete").then((res) => {
            if (res.status === 200) {
                toast.success("User deleted successfully");
            }
            handleDeleteAuthCookie();
            window.location.reload();
        });
        setDeleteModal(false);
    };

    useEffect(() => {
        if (deleteModal) {
            document.body.classList.add("overflow-hidden");

            return () => {
                document.body.classList.remove("overflow-hidden");
            };
        }
    }, [deleteModal]);

    const handleWidthdrawMoney = async () => {
        try {
            axiosInstance.post("/credits/withdraw", { amount: +withdrawAmount }).then((res) => {
                console.log(res);
                toast.success(res.data[language]);
                setWithdrawModal(false);
            });
        } catch (err) {
            console.log(err);
            toast.error(err.response.data[language]);
        }
    };

    return (
        <>
            <Navbar navClass="defaultscroll is-sticky" />
            {deleteModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-slate-900/20 z-50 p-4">
                    <div className="bg-white dark:bg-slate-900 p-6 rounded-md shadow-lg w-full max-w-md">
                        <div className="flex items-center mb-4">
                            <FiAlertCircle className="w-6 h-6 text-red-600" />
                            <h5 className="text-lg font-semibold ml-2 text-red-600">
                                {t.confirmDeletion}
                            </h5>
                        </div>
                        <p className="text-slate-400 mb-4">{t.confirmDeletionMsg}</p>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setDeleteModal(false)}
                                className="py-2 px-4 mr-2 font-semibold tracking-wide align-middle duration-500 text-base text-center bg-gray-500 text-white rounded-md"
                            >
                                {t.cancel}
                            </button>
                            <button
                                onClick={deleteAccountHandler}
                                className="py-2 px-4 font-semibold tracking-wide align-middle duration-500 text-base text-center bg-red-600 text-white rounded-md"
                            >
                                {t.delete}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {withdrawModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-slate-900/20 z-50 p-4">
                    <div className="bg-white dark:bg-slate-900 p-4 sm:p-6 md:p-8 rounded-md shadow-lg w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl">
                        <div className="flex items-center mb-3 sm:mb-4">
                            <h5 className="text-base sm:text-lg font-semibold ml-2 text-orange-600">
                                {t.withDrawHeader}
                            </h5>
                        </div>
                        <p className="text-sm sm:text-base text-slate-400 mb-3 sm:mb-4">
                            {t.withDrawText}
                        </p>
                        <input
                            className="border border-gray-300 rounded-md w-full p-2 mb-4 focus:outline-none focus:border-orange-500 dark:bg-black dark:text-white"
                            value={withdrawAmount}
                            onChange={(e) => setWithdrawAmount(e.target.value)}
                        />
                        <div className="flex flex-col sm:flex-row sm:justify-end gap-2 sm:gap-4">
                            <button
                                onClick={() => setWithdrawModal(false)}
                                className="py-2 px-4 font-semibold tracking-wide duration-300 text-sm sm:text-base bg-gray-500 text-white rounded-md w-full sm:w-auto"
                            >
                                {t.cancel}
                            </button>
                            <button
                                onClick={handleWidthdrawMoney}
                                className="py-2 px-4 font-semibold tracking-wide duration-300 text-sm sm:text-base bg-orange-500 text-white rounded-md w-full sm:w-auto"
                            >
                                {t.withdrawMonay}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <section className="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
                <div className="md:container container-fluid relative">
                    <div className="relative overflow-hidden md:rounded-md shadow dark:shadow-gray-700 h-52 bg-[url('../../assets/images/hero/pages.jpg')] bg-center bg-no-repeat bg-cover"></div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="md:flex">
                        <Usertab
                            userName={user?.name}
                            userEmail={user?.email}
                            userCredit={user?.credit}
                        />

                        <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                            <div className="flex justify-between items-center">
                                <div className="flex mb-4 flex-col gap-2 md:text-lg">
                                    <p className="font-semibold">
                                        {language === "ka"
                                            ? "მომხმარებლის რეფერალური კოდი"
                                            : "User referal code"}
                                    </p>
                                    <div className="flex items-center">
                                        <p className="mr-4">
                                            {isReferralVisible ? user?.referral_code : "******"}
                                        </p>
                                        <button onClick={toggleReferralVisibility} className="mr-4">
                                            {isReferralVisible ? (
                                                <FiEyeOff className="inline-block" />
                                            ) : (
                                                <FiEye className="inline-block" />
                                            )}
                                        </button>
                                        <button onClick={handleCopy}>
                                            <FiCopy className="inline-block mr-1" />
                                        </button>
                                    </div>
                                </div>
                                <button
                                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md mt-5 hover:bg-orange-400"
                                    onClick={() => {
                                        if (user.credit < 20) {
                                            toast(
                                                language === "ka"
                                                    ? "მინიმუმ 20 ლარი უნდა გქონდეთ ანგარიში რომ გამოტანა შეძლოთ!"
                                                    : "You must have at least 20 GEL in your account to be able to withdraw!"
                                            );
                                        } else {
                                            setWithdrawModal(true);
                                        }
                                    }}
                                >
                                    {language === "ka" ? "კრედიტი" : "credit"}
                                </button>
                            </div>
                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                <h5 className="text-lg font-semibold mb-4">{t.personalDetail} :</h5>
                                <form onSubmit={changeUserDetailsHandler}>
                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                        <div>
                                            <label className="form-label font-medium">
                                                {t.userName} :{" "}
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <div className="form-icon relative mt-2">
                                                <FiUser className="w-4 h-4 absolute top-3 start-4"></FiUser>
                                                <input
                                                    type="text"
                                                    className="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 "
                                                    placeholder={user?.name}
                                                    id="firstname"
                                                    name="name"
                                                    required=""
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 gap-5">
                                            <div>
                                                <label className="form-label font-medium">
                                                    {t.phoneNumber} :
                                                </label>
                                                <div className="form-icon relative mt-2">
                                                    <FiPhone className="w-4 h-4 absolute top-3 start-4"></FiPhone>
                                                    <input
                                                        name="number"
                                                        id="number"
                                                        type="text"
                                                        className="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                                        placeholder={
                                                            user?.phone_number
                                                                ? user?.phone_number
                                                                : t.phoneNumber
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <input
                                        type="submit"
                                        id="submit"
                                        name="send"
                                        className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md mt-5 cursor-pointer hover:bg-orange-400"
                                        value={t.saveChanges}
                                    />
                                </form>
                            </div>

                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-6">
                                <div className="">
                                    <h5 className="text-lg font-semibold mb-4">
                                        {t.changePassword} :
                                    </h5>
                                    <form onSubmit={changePasswordHandler}>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                            <div>
                                                <label className="form-label font-medium">
                                                    {t.oldPassword} :
                                                </label>
                                                <div className="form-icon relative mt-2">
                                                    <FiKey className="w-4 h-4 absolute top-3 start-4"></FiKey>
                                                    <input
                                                        name="old_password"
                                                        type="password"
                                                        className="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                                        placeholder={t.oldPassword}
                                                        required=""
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label className="form-label font-medium">
                                                    {t.newPassword} :
                                                </label>
                                                <div className="form-icon relative mt-2">
                                                    <FiKey className="w-4 h-4 absolute top-3 start-4"></FiKey>
                                                    <input
                                                        name="new_password"
                                                        type="password"
                                                        className="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                                        placeholder={t.newPassword}
                                                        required=""
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label className="form-label font-medium">
                                                    {t.retypeNewPassword} :
                                                </label>
                                                <div className="form-icon relative mt-2">
                                                    <FiKey className="w-4 h-4 absolute top-3 start-4"></FiKey>
                                                    <input
                                                        name="new_password_confirmation"
                                                        type="password"
                                                        className="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                                        placeholder={t.retypeNewPassword}
                                                        required=""
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <button className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md mt-5 hover:bg-orange-400">
                                            {t.savePassword}
                                        </button>
                                    </form>
                                </div>
                            </div>

                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-6">
                                <h5 className="text-lg font-semibold mb-5 text-red-600">
                                    {t.deleteAccount} :
                                </h5>

                                <p className="text-slate-400 mb-4">{t.deleteConfirmation}</p>

                                <button
                                    onClick={() => setDeleteModal(true)}
                                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-red-600 text-white rounded-md"
                                >
                                    {t.delete}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollToTop />
        </>
    );
}
