import React, { useState } from "react";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import useCartStore from "../store/cartStore";
import { toast } from "sonner";
import useAuthMoadl from "../store/authWarningModal";
import useUserData from "../store/userStore";
import { handleGetLanguage } from "../utils/languageStorage";
import { checkQuantity } from "../api/cartApi";
import ShareButton from "./FaceBookShareButton";

export default function ProductDetail({ product }) {
    const [count, setCount] = useState(1);
    const [size, setSize] = useState("");
    const [colore, setColore] = useState("");
    const location = useLocation();

    const { addToCart } = useCartStore((state) => state);
    const { show } = useAuthMoadl((state) => state);
    const { user } = useUserData((state) => state);

    const navigate = useNavigate();
    const language = handleGetLanguage();

    const increments = () => {
        setCount(count + 1);
    };

    const decrements = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };

    // const sizes = product.sizes;

    const colors = product.colors;

    const translations = {
        en: {
            shopNow: "Shop Now",
            addToCart: "Add to Cart",
            overview: "Overview",
            size: "Size",
            quantity: "Quantity",
            colors: "Colors",
            youHaveToAdd: "You have to add color ",
            addedToCart: "You have added product to cart",
            digitalMarketing: "Digital Marketing Solutions for Tomorrow",
            experiencedMarketing: "Our Talented & Experienced Marketing Agency",
            createSkin: "Create your own skin to match your brand",
        },
        ka: {
            shopNow: "ყიდვა",
            addToCart: "კალათაში დამატება",
            overview: "მიმოხილვა",
            size: "ზომა",
            quantity: "რაოდენობა",
            colors: "ფერები",
            youHaveToAdd: "ფერი  უნდა დაამატოთ",
            addedToCart: "პროდუქტი კალათაში დაემატა",
            digitalMarketing: "ციფრული მარკეტინგის გადაწყვეტილებები მომავლისთვის",
            experiencedMarketing: "ჩვენი გამოცდილი მარკეტინგის სააგენტო",
            createSkin: "შექმენით საკუთარი კანის ბრენდის შესაბამისი",
        },
    };

    const t = translations[language] || translations.en;

    const handleCopy = () => {
        if (!user.name || !user.referral_code) {
            show("signUp");
        } else {
            navigator.clipboard.writeText(
                `onlybrands.ge${location.pathname}?referral_code=${user.referral_code}`
            );
            toast.success(
                language === "ka"
                    ? "რეფერალური ლინკი დაკოპირებულია ✔"
                    : "Link code copied to clipboard ✔"
            );
        }
    };

    // other User Refferal from URL

    const queryParams = new URLSearchParams(location.search);
    const referralFromQuery = queryParams.get("referral_code");

    const setSingleReferalInSession = () => {
        referralFromQuery && sessionStorage.setItem("other_referral_code", referralFromQuery);
        const referral = sessionStorage.getItem("other_referral_code");
        return referral;
    };

    const handleShopNow = async () => {
        if (!user?.id || !user?.email) {
            show();
        } else {
            if (!colore) {
                toast.error(t.youHaveToAdd);
            } else {
                const isQuantity = await checkQuantity({
                    id: product.id,
                    colore: colore,
                    quantity: count,
                });

                if (isQuantity === 200) {
                    const referral = setSingleReferalInSession();
                    const url = qs.stringifyUrl(
                        {
                            url: "/shop-checkout",
                            query: {
                                product: product?.id,
                                color: colore,
                                // size: size || undefined,
                                qtn: count,
                                referral: referral,
                            },
                        },
                        { skipNull: true, skipEmptyString: true }
                    );
                    navigate(url);
                }
            }
        }
    };

    return (
        <div className=" ">
            <h5 className="text-2xl font-semibold">{product?.name?.[language]}</h5>
            <div className="mt-2">
                <span className="text-slate-800 font-bold text-3xl me-1">
                    {product?.discounted_price}GEL{" "}
                    {product?.price && <del className="text-red-600"> {product?.price}GEL</del>}
                </span>
            </div>

            <div className="mt-4">
                <h5 className="text-lg font-semibold">{t.overview} :</h5>
                <p className="text-slate-400 mt-2">{product?.overview?.[language]}</p>
            </div>

            <div className="grid  grid-cols-1 gap-6 mt-4">
                <div className="flex items-center whitespace-nowrap">
                    <h5 className="text-lg font-semibold me-2 text-center ">{t.quantity}:</h5>
                    <div className="qty-icons ms-3 space-x-0.5">
                        <button
                            onClick={() => decrements()}
                            className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus"
                        >
                            -
                        </button>
                        <input
                            min="0"
                            name="quantity"
                            value={count}
                            onChange={() => {}}
                            type="number"
                            className="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity"
                        />
                        <button
                            onClick={() => increments()}
                            className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus"
                        >
                            +
                        </button>
                    </div>
                </div>

                <div className="flex items-center">
                    <h5 className="text-lg font-semibold me-2">{t.colors}:</h5>
                    <div className="space-x-2">
                        {colors &&
                            colors.map((item, i) => (
                                <button
                                    onClick={() => {
                                        setColore(item?.id);
                                    }}
                                    key={i}
                                    style={{ backgroundColor: item?.name }}
                                    className={`size-6 rounded-full ring-2  dark:ring-slate-800  inline-flex align-middle ${
                                        colore === item.id
                                            ? "ring-black dark:ring-white"
                                            : "ring-gray-200"
                                    }`}
                                    title={item?.name}
                                ></button>
                            ))}
                    </div>
                </div>
            </div>

            <div className="mt-4 space-x-1">
                <button
                    onClick={handleShopNow}
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2 hover:bg-orange-400"
                >
                    {t.shopNow}
                </button>

                <button
                    onClick={() => {
                        if (!user?.id || !user?.email) {
                            show();
                            return;
                        }

                        if (!colore) {
                            toast.error(t.youHaveToAdd);
                            return;
                        }

                        addToCart({
                            product_id: product?.id,
                            product: product,
                            quantity: count,
                            ...(referralFromQuery &&
                                referralFromQuery !== "" && { referral: referralFromQuery }),
                            // size,
                            color_id: colore,
                            price: product?.discounted_price || product?.price,
                        });

                        setCount(1);
                        setColore("");
                        setSize("");
                    }}
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white mt-2 active:opacity-50"
                >
                    {t.addToCart}
                </button>
            </div>
            <button
                onClick={handleCopy}
                className="whitespace-nowrap py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2 hover:bg-orange-400"
            >
                {language === "ka" ? "გააზიარე რეფერალურ ლინკი" : "Share Referal Link"}
            </button>
            <ShareButton
                language={language}
                product={product}
                referralLink={`onlybrands.ge${location.pathname}?referral_code=${user.referral_code}`}
            />
        </div>
    );
}
