import { create } from "zustand";
import { getCart, addItemCart, removeItemCart, updateItemQuantity } from "../api/cartApi"; // Adjust the path as necessary

const useCartStore = create((set, get) => ({
    cart: [],
    total_price: 0,
    error: null,

    initializeCart: async () => {
        try {
            const cartData = await getCart();
            set({ cart: cartData.data, total_price: cartData.total_price });
        } catch (err) {
            set({ error: err.message });
        }
    },

    setCart: (newCart) => set({ cart: newCart }),

    addToCart: async (item) => {
        try {
            // TODO ბექიდან რომ მიიღებ დამატებული აითემის ინფოს მერე ეგ ინფოჩასვი სთეითის განახლებაში
            await addItemCart(item);

            set((state) => ({
                cart: [...state.cart, item],
                total_price: state.total_price + item?.price * item?.quantity,
            }));
        } catch (err) {
            set({ error: err.message });
        }
    },

    removeFromCart: async (item) => {
        try {
            // BUG გასასწორებელია რაღაც. itemს ვერ პოულობს
            const updatedCartData = await removeItemCart(item);

            set((state) => ({
                cart: state.cart.filter((cartItem) => cartItem.id !== item.id),
                total_price:
                    state.total_price -
                    item?.quantity * (item.product.discounted_price || item.product.price),
            }));
            return updatedCartData;
        } catch (err) {
            set({ error: err.message });
        }
    },

    increaseQtn: async (item) => {
        try {
            await updateItemQuantity({
                id: item.id,
                quantity: item.quantity + 1,
            });

            set((state) => {
                let price = 0;

                const updatedCart = state.cart.map((cartItem) => {
                    if (cartItem.id === item.id) {
                        price = item.product.discounted_price || item.product.price;

                        return { ...cartItem, quantity: cartItem.quantity + 1 };
                    }
                    return cartItem;
                });

                return { cart: updatedCart, total_price: state.total_price + price };
            });
        } catch (err) {
            set({ error: err.message });
        }
    },

    decreaseQtn: async (item) => {
        try {
            const { removeFromCart } = get();

            // eslint-disable-next-line eqeqeq
            if (item.quantity == 1) {
                removeFromCart(item);
            } else {
                await updateItemQuantity({
                    id: item.id,
                    quantity: item.quantity - 1,
                });

                set((state) => {
                    let price = 0;

                    const updatedCart = state.cart.map((cartItem) => {
                        if (cartItem.id === item.id) {
                            price = item.product.discounted_price || item.product.price;

                            return { ...cartItem, quantity: cartItem.quantity - 1 };
                        }
                        return cartItem;
                    });

                    return { cart: updatedCart, total_price: state.total_price - price };
                });
            }
        } catch (err) {
            set({ error: err.message });
        }
    },
}));

export default useCartStore;
